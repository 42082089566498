import axiosInstance from './axios'

const handleErrors = (error) => {
    if (error.response) {
        const message = error.response.data.message;
        const errorMessage = message ? message : "something went wrong.";
        console.log(errorMessage);
    } else {
        console.log(error);
    }
};

export default {
    GET: async (url) => {
        try {
            const response = await axiosInstance.get(url);
            if (response) {
                return response.data;
            }
        } catch (e) {
            handleErrors(e);
            return Promise.reject(e);
        }
    },
    POST: async (url, body) => {
        console.log("🚀 ~ file: index.js ~ line 25 ~ POST: ~ body", url, body);
        try {
            const response = await axiosInstance.post(url, body);
            if (response) {
                return response.data;
            }
        } catch (e) {
            handleErrors(e);
            return Promise.reject(e);
        }
    },
};

import axios from "axios";
import envs from "../env.json";

let headers = {};
const axiosInstance = axios.create({
    baseURL: envs.API_URL,
    headers,
});

console.log("envs.API_URL", envs.API_URL);

export default axiosInstance;
import * as actionTypes from '../actionTypes/actionTypes';

const initialState = {
	userId: null,
	userToken: null,
};

const user = (state = initialState, action) => {

	const { type, payload } = action;

	switch (type) {
		case actionTypes.SET_USER:
			return {
				...state,
				userId: payload,
			};

		case actionTypes.SET_USER_TOKEN:
			return {
				...state,
				userToken: payload,
			};

		case actionTypes.CLEAR_SESSION:
			return {
				userId: null,
				userToken: null,
			};
		default:
			return state;
	}
};

export default user;
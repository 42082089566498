import React, {useEffect, useState} from 'react'

import './layout.css'

import Sidebar from '../sidebar/Sidebar'
import TopNav from '../topnav/TopNav';
import { connect } from 'react-redux';

import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Login from './../auth/Login'
import Projects from '../../pages/Projects'
import CreateProject from '../../pages/CreateProject'
import EditProject from '../../pages/EditProject'
import * as UserAction from '../../redux/action/userAction';

function Layout({ user, dispatch }) {

    const [user_id, set_user_id] = useState(null);
    const [user_token, set_user_token] = useState(null);

    useEffect(() => {
        (async () => {
            await set_user_id(localStorage.getItem("user_id"));
            await set_user_token(localStorage.getItem("user_token"));
        })();
    }, [])
    

    try 
    {
        dispatch(UserAction.setUserId(user_id));
        dispatch(UserAction.setUserToken(user_token));
        
        if(user_id == null) 
        {
            return (
                <BrowserRouter>
                    <Route render={(props) => (
                        <div className="layout theme-mode-light theme-color-blue">
                            <Switch>
                                <Route path='/' exact component={Login} />
                            </Switch>
                        </div>
                    )} />
                </BrowserRouter>
            )
        } 
        else 
        {
            return (
                <BrowserRouter>
                    <Route render={(props) => (
                        <div className="layout theme-mode-light theme-color-blue">
                            {/* <Sidebar {...props}/> */}
                            <div className="layout__content">
                                <TopNav />
                                <div className="layout__content-main">
                                    <Switch>
                                        <Route path='/' exact component={Projects} />
                                        <Route path='/create-project' exact component={CreateProject} />
                                        <Route path='/edit-project/:id' exact component={EditProject} />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    )} />
                </BrowserRouter>
            )
        }
    } 
    catch(error) 
    {
        console.log('error.response', error);
    }
}

const mapStateToProps = state => ({
    user: state.userId,
    token: state.userToken,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(Layout); 

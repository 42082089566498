import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { project_service } from '../services';
import swal from 'sweetalert';

export default function CreateProject() {

    const user_id = localStorage.getItem("user_id");
    const user_token = localStorage.getItem("user_token");

    const [project_name, setProjectName] = useState('');
    const [db_name, setDBName] = useState('');
    const [db_password, setDBPassword] = useState('');
    const [db_username, setDBUsername] = useState('');
    const [live_url, setWebsiteUrl] = useState('');
    const [table_name, setTableName] = useState('');
    const [host_name, setHostName] = useState('');
    const [project_type, setProjectType] = useState('');
    const [client_name, setClientName] = useState('');
    const [whatsapp_no, setWhatsappNo] = useState('');
    const [mobile_no, setMobileNo] = useState('');
    const [email_addr, setEmailAddr] = useState('');

    const handleProjectName = (e) => {
        setProjectName(e.target.value)
    }

    const handleDBName = (e) => {
        setDBName(e.target.value)
    }

    const handleDBPassword = (e) => {
        setDBPassword(e.target.value)
    }

    const handleDBUsername = (e) => {
        setDBUsername(e.target.value)
    }

    const handleWebsiteUrl = (e) => {
        setWebsiteUrl(e.target.value)
    }

    const handleTableName = (e) => {
        setTableName(e.target.value)
    }

    const handleHostName = (e) => {
        setHostName(e.target.value)
    }

    const handleProjectType = (e) => {
        setProjectType(e.target.value)
    }

    const handleClientName = (e) => {
        setClientName(e.target.value)
    }

    const handleWhatsappNo = (e) => {
        setWhatsappNo(e.target.value)
    }

    const handleMobileNo = (e) => {
        setMobileNo(e.target.value)
    }

    const handleEmailAddr = (e) => {
        setEmailAddr(e.target.value)
    }


    /* LIST PROJECT TYPE */
    const [project_type_list, setProjectTypeList] = useState({ project_types: [] });

    useEffect(() => {

        getProjectTypeList();

    }, [setProjectTypeList]);

    const getProjectTypeList = async () => {
        try {
            await project_service("", {
                "api": "get_master_data_list",
                "request_data":
                {
                    
                }
            }).then((response) => {

                if(response.code >= 200 && response.code <= 299) 
                {

                    setProjectTypeList({ project_types: response.data.project_types_list });
                }
                else 
                {
                    alert(response.msg);
                }

            });

        }
        catch (error) {
            console.log(error);
        }
    }

    /* ADD PROJECT */
    const AddProject = async () => {
        try {
            await project_service("", {
                "api": "add_project",
                "request_data":
                {
                    "user_id": user_id,
                    "user_token": user_token,
                    "project_name": project_name,
                    "db_name": db_name,
                    "db_password": db_password,
                    "db_username": db_username,
                    "live_url": live_url,
                    "table_name": table_name,
                    "host_name": host_name,
                    "project_type": project_type,
                    "client_name": client_name,
                    "whatsapp_no": whatsapp_no,
                    "mobile_no": mobile_no,
                    "email_addr": email_addr
                }
            }).then((response) => {

                if(response.code >= 200 && response.code <= 299) 
                {
                    swal("", response.msg, "success");
                    window.setTimeout(function () {
                        window.location.href = '/';
                    }, 3000);
                }
                else 
                {
                    swal("", response.msg, "error");
                }

            });

        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {

        if(user_id == "" || user_token == "")
        {
            window.location.href = '/';
        }

    }, [])

    return (
        <div>
            <h2 className="page-header">
                Create Project
            </h2>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <form>
                                <fieldset>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group row mb-3">
                                                <div class="col-md-6">
                                                    <label>Project Name</label>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        name='project_name' 
                                                        id="project_name" 
                                                        placeholder="Enter Project Name"
                                                        value={project_name}
                                                        onChange={handleProjectName}
                                                    />
                                                </div>
                                                <div class="col-md-6">
                                                    <label>DB Name</label>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        name='db_name' 
                                                        id="db_name" 
                                                        placeholder="Enter DB Name"
                                                        value={db_name}
                                                        onChange={handleDBName} 
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <div class="col-md-6">
                                                    <label>DB Password</label>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        name='db_password' 
                                                        id="db_password" 
                                                        placeholder="Enter DB Password"
                                                        value={db_password}
                                                        onChange={handleDBPassword}
                                                    />
                                                </div>
                                                <div class="col-md-6">
                                                    <label>DB Username</label>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        name='db_username' 
                                                        id="db_username" 
                                                        placeholder="Enter DB Username"
                                                        value={db_username}
                                                        onChange={handleDBUsername} 
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <div class="form-group col-md-6">
                                                    <label>Website URL</label>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        name='live_url' 
                                                        id="live_url" 
                                                        placeholder="Enter Website URL"
                                                        value={live_url}
                                                        onChange={handleWebsiteUrl} 
                                                    />
                                                </div>
                                                <div class="col-md-6">
                                                    <label>Table Name</label>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        name='table_name' 
                                                        id="table_name" 
                                                        placeholder="Enter Table Name"
                                                        value={table_name}
                                                        onChange={handleTableName} 
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <div class="col-md-6">
                                                    <label>Host Number</label>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        name='host_name' 
                                                        id="host_name" 
                                                        placeholder="Enter Host Number"
                                                        value={host_name}
                                                        onChange={handleHostName} 
                                                    />
                                                </div>
                                                <div class="col-md-6">
                                                    <label>Project Type</label>
                                                    <select 
                                                        class="form-control"
                                                        name="project_type"
                                                        id="project_type"
                                                        onChange={handleProjectType}
                                                    >
                                                        <option value="">Select Project Type</option>
                                                        {
                                                            project_type_list.project_types && project_type_list.project_types.map((item) => (
                                                                <option value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <div class="col-md-6">
                                                    <label>Client Name</label>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        name='client_name' 
                                                        id="client_name" 
                                                        placeholder="Enter Client Name"
                                                        value={client_name}
                                                        onChange={handleClientName} 
                                                    />
                                                </div>
                                                <div class="col-md-6">
                                                    <label>Whatsapp No.</label>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        name='whatsapp_no' 
                                                        id="whatsapp_no" 
                                                        placeholder="Enter Whatsapp Number"
                                                        value={whatsapp_no}
                                                        onChange={handleWhatsappNo} 
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <div class="col-md-6">
                                                    <label>Mobile Number</label>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        name='mobile_no' 
                                                        id="mobile_no" 
                                                        placeholder="Enter Mobile Number"
                                                        value={mobile_no}
                                                        onChange={handleMobileNo} 
                                                    />
                                                </div>
                                                <div class="col-md-6">
                                                    <label>Email Address</label>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        name='email_addr' 
                                                        id="email_addr" 
                                                        placeholder="Enter Email Address"
                                                        value={email_addr}
                                                        onChange={handleEmailAddr} 
                                                    />
                                                </div>
                                            </div>

                                            <button type="button" class="btn btn-primary" onClick={AddProject}>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import './login.css';
import profile from '../../assets/images/a.png';
import { project_service } from '../../services';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import * as UserAction from '../../redux/action/userAction';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;

function Login({ user, dispatch }) {

    console.log("USER_ID", user);

    const [mobile_no, setMobileNo] = useState('');
    const [password, setPassword] = useState('');

    const handleMobileNo = (e) => {
        setMobileNo(e.target.value)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const LoginUser = async () => {
        try 
        {
            const response = await project_service("", {
                "api": "user_login",
                "request_data":
                {
                    "mobile_no": mobile_no,
                    "password": password
                }
            })

            if(response)
            {
                if (response.code >= 200 && response.code <= 299) {
                    await localStorage.setItem('user_id', response.data.user_id);
                    await localStorage.setItem('user_token', response.data.user_token);

                    dispatch(UserAction.setUserId(response.data));

                    swal("", response.msg, "success");
                    window.setTimeout(function () {
                        window.location.href = '/';
                    }, 2000);
                }
                else {
                    swal("", response.msg, "error");
                }
            }
            else
            {
                console.log("response", response);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    }

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    useEffect(() => {

    }, [])

    return (
        <div className="main">
            <div className="sub-main">
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="imgs">
                            <div className="container-image">
                                <img src={profile} alt="profile" className="profile" />
                            </div>
                        </div>
                        <div className='login-contents'>
                            <h1>PROJECT TRACKER</h1>
                            <div>
                                <input
                                    type="number"
                                    name='mobile_no'
                                    placeholder="Enter Mobile Number"
                                    className="name cus-text-number-field"
                                    value={mobile_no}
                                    onChange={handleMobileNo}
                                />
                            </div>
                            <div className="second-input">
                                <input
                                    type={passwordShown ? "text" : "password"}
                                    placeholder="Enter Password"
                                    className="name"
                                    value={password}
                                    onChange={handlePassword}
                                />
                                <i className='show-password' onClick={togglePasswordVisiblity}>{eye}</i>{" "}
                            </div>
                            <div className="login-button">
                                <button className="login-submit" type="submit" onClick={LoginUser}>
                                    Login
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.userId,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(Login);
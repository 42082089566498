
import * as actionTypes from '../actionTypes/actionTypes';


export const setUserId = payload => ({
	type: actionTypes.SET_USER,
	payload,
});

export const setUserToken = payload => ({
	type: actionTypes.SET_USER_TOKEN,
	payload,
});

export const clearSession = () => ({
	type: actionTypes.CLEAR_SESSION,
});

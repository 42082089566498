import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { project_service } from '../services';
import * as ReactBootstrap from 'react-bootstrap';
import swal from 'sweetalert';
import { connect } from 'react-redux';

function Projects(user, token) {

    /* List Projects */
    const [project_list, setProjectList] = useState({ projects: [] });

    const user_id = localStorage.getItem("user_id");
    const user_token = localStorage.getItem("user_token");

    console.log("PROJECT_USER_ID", user);
    console.log("PROJECT_USER_TOKEN", token);

    useEffect(() => {

        getprojectsList();

    }, [setProjectList]);

    const getprojectsList = async () => {
        try {
            await project_service("", {
                "api": "get_project_list",
                "request_data":
                {
                    "user_id": user_id,
                    "user_token": user_token
                }
            }).then((response) => {

                if (response.code >= 200 && response.code <= 299) {

                    setProjectList({ projects: response.data.project_list });
                }
                else {
                    alert(response.msg);
                }

            });

        }
        catch (error) {
            console.log(error);
        }
    }

    /* Update Project Status */

    const UpdateProjectStatus = async (project_id, project_status) => {

        try {
            await project_service("", {
                "api": "update_project_status",
                "request_data":
                {
                    "user_id": user_id,
                    "user_token": user_token,
                    "project_id": project_id,
                    "is_project_live": project_status
                }
            }).then((response) => {

                if (response.code >= 200 && response.code <= 299) {
                    swal("", "Project status updated successfully.", "success");
                }
                else {
                    swal("", "Error while updating project status.", "error");
                }

            });

        }
        catch (error) {
            console.log(error);
        }

        getprojectsList();
    }

    const generate_project_status = (project_status) => {
        if (project_status == "Active") {
            return (
                <span className='project-success-status'>{project_status}</span>
            )
        }
        else {
            return (
                <span className='project-danger-status'>{project_status}</span>
            )
        }
    }

    return (
        <div>
            <h2 className="page-header">
                Project List
                <Link to="/create-project" className="page-right-header">Add Project</Link>
            </h2>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <ReactBootstrap.Table striped bordered hover className="text-center">
                                <thead>
                                    <tr>
                                        <th>Sr No.</th>
                                        <th>Project Name</th>
                                        <th>URL</th>
                                        <th>Addedon</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        project_list.projects && project_list.projects.map((item) => (
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>{item.project_name}</td>
                                                <td>
                                                    <Link to={{ pathname: item.live_url }} target="_blank">
                                                        {item.live_url}
                                                    </Link>
                                                </td>
                                                <td>{item.addedon}</td>
                                                <td>
                                                    {
                                                        generate_project_status(item.project_status)
                                                    }
                                                </td>
                                                <td>
                                                    <Link
                                                        to={'/edit-project/' + item.id}
                                                        className='btn btn-primary'>
                                                        <i class="bx bx-edit bx-sm"></i>
                                                    </Link>

                                                    <Link
                                                        className={`btn btn-${item.is_project_live == 0 ? 'success' : 'danger'}`}
                                                        onClick={() => UpdateProjectStatus(item.id, item.is_project_live == 1 ? '0' : '1')}
                                                    >
                                                        <i class={`bx bx-${item.is_project_live == 0 ? 'like' : 'dislike'} bx-sm`}></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </ReactBootstrap.Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    user: state.userId,
    token: state.userToken,
});

export default connect(mapStateToProps, null)(Projects);;